<template>
  <div>
    <ul class="menmberbox" v-loading="loading" v-if="leader==1 && auditData.length>0">
      <li v-for="(item,index) in auditData">
        <span class="left">
          <img :src="item.Avatar" alt />
        </span>
        <div class="right">
          <h2 class="titleh2">
            <span class="tit">{{item.Username}}</span>
            <span class="tag settag" @click="handelSetteamAuditMember({
              tid:item.Tid,
              memberUid:item.Uid,
              type:2,
              index:index
            })">拒绝</span>
            <span
              class="tag warntag"
              @click="handelSetteamAuditMember({
              tid:item.Tid,
              memberUid:item.Uid,
              type:1,
              index:index
            })"
            >通过审核</span>
          </h2>
          <p class="textp">{{item.Reason}}</p>
        </div>
      </li>
    </ul>
    <ul class="menmberbox" v-loading="loading" v-if="leader==1 && listData.length>0">
      <li v-for="(item,index) in listData">
        <span class="left">
          <img :src="item.Avatar" alt />
        </span>
        <div class="right">
          <h2 class="titleh2">
            <span class="tit">{{item.Username}}</span>
            <span class="tag settag" @click="handelSetteamAuditMember({
              tid:item.Tid,
              memberUid:item.Uid,
              type:3,
              index:index
            })">移除</span>
            <span class="tag successtag">已审核</span>
          </h2>
          <p class="textp">{{item.Reason}}</p>
        </div>
      </li>
    </ul>
    <ul class="menmberbox2" v-if="leader!==1 && listData.length>0">
      <li v-for="item in listData">
        <div class="imgbox">
          <img :src="item.Avatar" alt />
        </div>
        <h2 class="namebox">{{item.Username}}</h2>
        <i class="iconfont iconbianzu13"></i>
      </li>
    </ul>
  </div>
</template>
<script>
import { teamMemberRequest, teamAuditMemberRequest } from "@/api/project.js";
export default {
  components: {},
  data() {
    return {
      currentPage: 1,
      total: "",
      pageCount: 10,
      loading: true,
      leader: 0,
      auditData: [],
      listData: []
    };
  },
  created() {
    this.getTeamMember(this.tid);
  },
  computed: {
    tid() {
      return this.$route.params.tid || ""; //当前项目id
    }
  },
  methods: {
    async getTeamMember(tid, val) {
      try {
        this.loading = true;
        setTimeout(() => {
          this.loading = false;
        }, 3000);
        val = val || "1";
        const result = await teamMemberRequest({
          tid: tid,
          page: val
        });
        if (result.status == 200) {
          console.log(result);
          this.loading = false;
          this.leader = result.data.leader;
          this.auditData = result.data.audit;
          this.listData = result.data.list;
          this.total = result.data.totalCount;
          this.pageCount = result.data.pageCount;
        }
      } catch (err) {}
    },
    handleCurrentChange(val) {
      this.getTeamMember(this.tid, val);
    },
    async setteamAuditMember(opt) {
      try {
        const result = await teamAuditMemberRequest({
          tid: opt.tid,
          memberUid: opt.memberUid,
          type: opt.type
        });
        if (result.status == 200) {
          this.$message({
            type: "success",
            message: `${result.message}`
          });
          this.getTeamMember(this.tid);
        }
        else{
          this.$message({
            type: "info",
            message: `${result.message}`
          });
        }
      } catch (err) {}
    },
    handelSetteamAuditMember(opt) {
      console.log(11);

      var text = "";
      if (opt.type == 1) {
        //（1通过审核，2拒绝，3移除）
        text = "确认通过审核, 是否继续";
      } else if (opt.type == 2) {
        text = "确认拒绝审核, 是否继续 ";
      } else {
        text = "确认移除审核, 是否继续";
      }
      this.$confirm(text, "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning"
      })
        .then(() => {
          this.setteamAuditMember(opt);
        })
        .catch(() => {
          this.$message({
            type: "info",
            message: "已取消"
          });
        });
    }
  }
};
</script>
<style  lang="less" scoped>
.menmberbox {
  padding: 0 15px;
  overflow: hidden;
  & > li {
    padding: 20px 0;
    border-bottom: 1px dotted #e5e5e5;
    overflow: hidden;
  }
  .left {
    width: 66px;
    height: 66px;
    border-radius: 70px;
    margin-right: 20px;
    overflow: hidden;
    float: left;
    border: 1px solid #e5e5e5;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .right {
    flex: 1;
    color: #222;
    .titleh2 {
      font-size: 16px;
      margin-bottom: 5px;
      .tit {
        cursor: pointer;
      }
    }
    .textp {
      font-size: 13px;
      line-height: 22px;
      overflow: hidden;
      color: #888;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
  }
  .setbox {
    float: right;
    width: 170px;
    .iconfont {
      font-size: 36px;
      color: #3385ff;
      margin-right: 20px;
    }
  }
  .tag {
    border-radius: 4px;
    font-size: 11px;
    text-align: center;
    color: #fff;
    float: right;
    padding: 0 10px;
    cursor: pointer;
    box-sizing: border-box;
    min-width: 60px;
    margin-left: 15px;
    box-sizing: border-box;
    height: 24px;
    line-height: 24px;
    &.warntag {
      background: #2db6d4;
    }
    &.successtag {
      background: none;
      cursor: default;
      color: #6774cd;
      opacity: 0.8;
    }
    &.settag {
      background: #fff;
      border: 1px solid #ccc;
      color: #888;
    }
    &:hover {
      opacity: 0.8;
    }
  }
}
.menmberbox2 {
  padding: 15px 0;
  overflow: hidden;
  & > li {
    width: 100px;
    text-align: center;
    position: relative;
    float: left;
  }
  .imgbox {
    width: 56px;
    height: 56px;
    border-radius: 70px;
    overflow: hidden;
    border: 1px solid #e5e5e5;
    margin: 0 auto;
    img {
      width: 100%;
      height: 100%;
      display: block;
    }
  }
  .namebox {
    font-size: 14px;
    color: #888;
    text-align: center;
    line-height: 14px;
    margin-top: 10px;
  }
  .iconfont {
    position: absolute;
    top: 42px;
    right: 15px;
    font-size: 20px;
    color: #2db6d4;
  }
}
</style>