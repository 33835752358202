var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.leader==1 && _vm.auditData.length>0)?_c('ul',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"menmberbox"},_vm._l((_vm.auditData),function(item,index){return _c('li',[_c('span',{staticClass:"left"},[_c('img',{attrs:{"src":item.Avatar,"alt":""}})]),_c('div',{staticClass:"right"},[_c('h2',{staticClass:"titleh2"},[_c('span',{staticClass:"tit"},[_vm._v(_vm._s(item.Username))]),_c('span',{staticClass:"tag settag",on:{"click":function($event){return _vm.handelSetteamAuditMember({
            tid:item.Tid,
            memberUid:item.Uid,
            type:2,
            index:index
          })}}},[_vm._v("拒绝")]),_c('span',{staticClass:"tag warntag",on:{"click":function($event){return _vm.handelSetteamAuditMember({
            tid:item.Tid,
            memberUid:item.Uid,
            type:1,
            index:index
          })}}},[_vm._v("通过审核")])]),_c('p',{staticClass:"textp"},[_vm._v(_vm._s(item.Reason))])])])}),0):_vm._e(),(_vm.leader==1 && _vm.listData.length>0)?_c('ul',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticClass:"menmberbox"},_vm._l((_vm.listData),function(item,index){return _c('li',[_c('span',{staticClass:"left"},[_c('img',{attrs:{"src":item.Avatar,"alt":""}})]),_c('div',{staticClass:"right"},[_c('h2',{staticClass:"titleh2"},[_c('span',{staticClass:"tit"},[_vm._v(_vm._s(item.Username))]),_c('span',{staticClass:"tag settag",on:{"click":function($event){return _vm.handelSetteamAuditMember({
            tid:item.Tid,
            memberUid:item.Uid,
            type:3,
            index:index
          })}}},[_vm._v("移除")]),_c('span',{staticClass:"tag successtag"},[_vm._v("已审核")])]),_c('p',{staticClass:"textp"},[_vm._v(_vm._s(item.Reason))])])])}),0):_vm._e(),(_vm.leader!==1 && _vm.listData.length>0)?_c('ul',{staticClass:"menmberbox2"},_vm._l((_vm.listData),function(item){return _c('li',[_c('div',{staticClass:"imgbox"},[_c('img',{attrs:{"src":item.Avatar,"alt":""}})]),_c('h2',{staticClass:"namebox"},[_vm._v(_vm._s(item.Username))]),_c('i',{staticClass:"iconfont iconbianzu13"})])}),0):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }